<template>
    <main class="p-contact">
        <div class="content-outer">
            <div class="content" v-if="content">
                <h1>{{ content.title }}</h1>

                <div class="subtitle">{{ $t('contact.forProjectPartnership') }}</div>
                <div class="email"><a :href="'mailto:' + content.email">{{ content.email }}</a></div>
                <div class="phone">{{ content.phone }}</div>

                <div class="subtitle">{{ $t('contact.visitUs') }}</div>
                <div class="address" v-html="content.address"></div>
                <a :href="content.googleMapsLink" target="_blank" rel="noopener" class="gmap-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                        <g fill="#737373">
                            <path d="M7.5 19.439c-.09 0-.177-.04-.236-.109C6.986 19.009.442 11.419.442 7.62.442 3.729 3.608.562 7.5.562s7.058 3.167 7.058 7.06c0 3.797-6.544 11.388-6.822 11.708-.06.07-.145.11-.236.11zm0-18.253c-3.547 0-6.433 2.887-6.433 6.435 0 3.18 5.247 9.608 6.433 11.021 1.186-1.413 6.433-7.843 6.433-11.021 0-3.547-2.886-6.435-6.433-6.435z"/>
                            <path d="M7.5 10.31c-1.28 0-2.32-1.04-2.32-2.32 0-1.279 1.04-2.32 2.32-2.32 1.28 0 2.32 1.041 2.32 2.32 0 1.28-1.04 2.32-2.32 2.32zm0-4.015c-.935 0-1.696.76-1.696 1.696 0 .933.761 1.694 1.696 1.694.935 0 1.696-.76 1.696-1.694 0-.935-.761-1.696-1.696-1.696z"/>
                        </g>
                    </svg>
                    {{ $t('contact.googleMaps') }}
                </a>
            </div>
            <div class="image" v-if="content">
                <div class="image__main"><img :src="content.imageUrl" alt="" /></div>
                <img :src="content.imageShadowUrl !== ''? content.imageShadowUrl : content.imageUrl" class="image__shadow" alt="" />
            </div>
        </div>
        <Cta :type="'big'" :ctaText="$t('global.ctaInsta')" :href="content.googleMapsLink" target="_blank" />
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Cta from '@/components/Cta';
export default {
    name: 'Contact',

 components: {
        Cta
    },


    data() {
        return {
            content: null,
        }
    },

    created() {
        this.$craft.getContentBySlug(this.$t('nav.contact.slug'))
            .then(res => {
                this.content = res.data;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                this.initAnimations();
            })
            .then(() => {
                this.$store.commit('SET_OTHER_LOCALES_URLS', this.content.otherLocalesUrls);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.fromTo('.image__main img', {
                y: -100,
            }, {
                y: 0,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.image__main img',
                    start: 'top 25%',
                    end: '+=100%',
                    scrub: 0.8,
                },
            });

            gsap.to('.image__shadow', {
                xPercent: 25,
                yPercent: 17,
                duration: 1,
                ease: 'Power1.inOut',
                scrollTrigger: {
                    trigger: '.image__shadow',
                    start: 'top 20%',
                    end: 'bottom bottom',
                },
            });

            ScrollTrigger.refresh(true);
        }
    }
}
</script>